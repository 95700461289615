import APP_CONST from '@/constants/AppConst';
import analyticsStore from '@/store/modules/analyticsStore';
import { Vue, Component, Prop } from 'vue-property-decorator';
import APP_UTILITIES from '@/utilities/commonFunctions';


@Component({
    components: {
    }
  })


export default class AnalyticsProgramDropdown extends Vue {
    @Prop()
    isDropdownDisabled!:boolean

    private isDropdownOpen: boolean = false
    private localAppConstant=APP_CONST;
    selectedStatus: number = 1;
    public highestRole:number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie("highest_role")))

    get analyticsProgramDropdown(){
        return analyticsStore.analyticsProgramDropdown
    }

    get selectedProgramData(){
        return analyticsStore.slectedProgram
    }

    get dropdownResponseStatus(){
        return analyticsStore.programDropdownStatus
    }

    get filteredDropdownValues() {
        if(!this.analyticsProgramDropdown.activePrograms){
            this.selectedStatus = APP_CONST.TWO;
        }
        return (this.selectedStatus==APP_CONST.ONE) ? this.analyticsProgramDropdown.activePrograms : this.analyticsProgramDropdown.disabledPrograms;
    }

    openDropdown() {
        if(!this.isDropdownOpen){
            this.selectedStatus = this.selectedProgramData && this.selectedProgramData.status == APP_CONST.ONE ? APP_CONST.ONE : APP_CONST.TWO;
        }
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    filterByStatus(status: number) {
        this.selectedStatus = status;
    }

    changeSelectedProgram(program: any){
        analyticsStore.programNameSelection(program.programId);
        this.$emit('selectProgramData', program)
        this.isDropdownOpen = false
    }

    formatDate(date: string) {
        return date ? APP_UTILITIES.formatDate(date, false, true) : '';
    }

}