var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-container"},[_c('div',{class:[
      'form-dropdown',
      _vm.analyticsProgramDropdown &&
      _vm.analyticsProgramDropdown.allPrograms.length == _vm.localAppConstant.ONE
        ? 'disable-dropdown'
        : '' ]},[_c('div',{class:[_vm.analyticsProgramDropdown &&
      _vm.analyticsProgramDropdown.allPrograms.length > _vm.localAppConstant.ONE && _vm.isDropdownDisabled ?'disable-program-dropdown':'', 'form-dropdown-title'],on:{"click":function($event){return _vm.openDropdown()}}},[(_vm.dropdownResponseStatus == _vm.localAppConstant.RESPONSE_200)?_c('span',{staticClass:"selected-program-value",attrs:{"bx-attr":"select-program"}},[_c('em',[(
              _vm.analyticsProgramDropdown &&
                _vm.analyticsProgramDropdown.allPrograms.length > _vm.localAppConstant.ONE
            )?_c('img',{attrs:{"src":require("../../../../assets/images/scholars/add-new-scholars/arrowDown.svg"),"alt":""}}):_vm._e()]),_vm._v(" "+_vm._s(_vm.selectedProgramData && _vm.selectedProgramData.programName)+" ")]):_vm._e()]),(_vm.dropdownResponseStatus == _vm.localAppConstant.RESPONSE_200)?_c('span',{staticClass:"program-date"},[_vm._v(_vm._s(_vm.selectedProgramData && _vm.selectedProgramData.programStartDate && _vm.formatDate(_vm.selectedProgramData.programStartDate))+" - "+_vm._s(_vm.selectedProgramData && _vm.selectedProgramData.programEndDate && _vm.formatDate(_vm.selectedProgramData.programEndDate)))]):_vm._e(),(_vm.isDropdownOpen)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
        function () {
          _vm.isDropdownOpen = false;
        }
      ),expression:"\n        () => {\n          isDropdownOpen = false;\n        }\n      "}],staticClass:"dropdown-submenu",class:[_vm.highestRole < _vm.localAppConstant.SIX ? 'dropdown-submenu-tabs':'']},[(_vm.highestRole < _vm.localAppConstant.SIX)?_c('div',{staticClass:"nav-tabs"},[_c('ul',{attrs:{"uk-tab":""}},[_c('li',[_c('span',[_c('img',{staticClass:"arrow-down",attrs:{"src":require("../../../../assets/images/scholars/add-new-scholars/arrow-down-transform.svg"),"alt":""},on:{"click":function($event){_vm.isDropdownOpen = false}}}),_c('a',{class:[
                _vm.selectedStatus == _vm.localAppConstant.ONE && 'active' ],on:{"click":function($event){return _vm.filterByStatus(_vm.localAppConstant.ONE)}}},[_vm._v(_vm._s("Active Programs"))])])]),_c('li',[_c('a',{class:[
                _vm.selectedStatus == _vm.localAppConstant.TWO && 'active'
              ],on:{"click":function($event){return _vm.filterByStatus(_vm.localAppConstant.TWO)}}},[_vm._v(_vm._s("Disabled Programs"))])])])]):_vm._e(),_c('ul',{staticClass:"dropdown-submenu-list",class:[this.analyticsProgramDropdown.disabledPrograms.length && _vm.selectedStatus == _vm.localAppConstant.TWO && 'active' && 'disable-listing', _vm.highestRole < _vm.localAppConstant.SIX ? 'dropdown-submenu-list-tabs':'']},_vm._l((_vm.filteredDropdownValues),function(programs){return _c('li',{key:programs.id,class:[_vm.highestRole < _vm.localAppConstant.SIX ? 'li-with-tabs':'li-without-tabs'],attrs:{"title":programs.name},on:{"click":function($event){return _vm.changeSelectedProgram(programs)}}},[_c('a',{class:[
              _vm.selectedProgramData &&
              _vm.selectedProgramData.programName == programs.programName
                ? 'selected-program'
                : '' ]},[_vm._v(" "+_vm._s(programs.programName)+" ")])])}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }